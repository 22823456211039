import React from "react";
import theme from "../../theme";
import styled from "styled-components";

const Icon = styled.img`
  margin: 5px auto;
  max-width: 100%;
  max-height: 128px;
  height: auto;
`;

const Header = styled.h4`
  font-size: 28px;
  color: ${theme.colors.blue};
  text-align: center;
  margin: 5px 0;
`;

const Desc = styled.p`
  font-size: 14px;
  color: ${theme.colors.blue};
  text-align: center;
`;

const Footer = styled.div`
  font-size: 18px;
  color: ${theme.colors.pink};
  text-align: center;
  padding: 25px 8%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background-color: #707070;
  }
`;

const Tile = styled.div`
  border-radius: 5px;
  padding: 40px 30px 20px;
  background-color: #fff;
  min-height: 520px;
  transition: all 0.25s;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${theme.screens.lgScreen} {
    min-height: unset;
    padding: 40px 25px;
    margin-bottom: 70px;
  }

  ${theme.mixins.onEvent} {
    box-shadow: ${theme.shadows.shadow};
  }
`;

const IconTile = ({ icon, header, desc, footer }) => {
  return (
    <div>
      <Tile>
        {/* <Icon
                    src={icon}
                    alt=""
                /> */}
        <video
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          style={{ maxWidth: 130, margin: "0 auto" }}
        >
          <source src={icon} type="video/mp4" />
        </video>
        <Header>{header}</Header>
        <Desc>{desc}</Desc>
        <Footer>{footer}</Footer>
      </Tile>
    </div>
  );
};

export default IconTile;
