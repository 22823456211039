import "styles/pages/page-career.scss";

import React from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TitleWithLine from "components/TitleWithLine";
import IconTile from "components/IconTile";
import CareerTile from "components/CareerTile";
import NewContactForm from "components/NewContactForm";

const Career = ({ pageContext }) => {
  const career = pageContext.pageContent;

  const video_icons = [
    require("assets/videos/icons/usability.mp4").default,
    require("assets/videos/icons/knowledge.mp4").default,
    require("assets/videos/icons/partners.mp4").default,
  ];
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        image={require("assets/images/career-img-1.png").default}
        title={pageContext.title}
      />
      <section className="section-joinus">
        <div className="container">
          <div className="row row--custom-order">
            <div className="col-lg-5">
              <div className="section-joinus__inner">
                <TitleWithLine variantY="bottom">
                  {career.sectionJuTitle}
                </TitleWithLine>
                <div
                  className="section-joinus__text"
                  dangerouslySetInnerHTML={{ __html: career.sectionJuText }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                className="img-fluid section-joinus__img"
                src={career.sectionJuImage?.sourceUrl}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-start">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                className="img-fluid section-start__img"
                src={career.sectionTihisImage?.sourceUrl}
              />
            </div>
            <div className="col-lg-6">
              <div className="section-start__inner">
                <TitleWithLine variantY="bottom">
                  {career.sectionTihisTitle}
                </TitleWithLine>
                <div
                  className="section-start__text"
                  dangerouslySetInnerHTML={{ __html: career.sectionTihisText }}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-matters">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-matters__inner">
                <TitleWithLine variantY="bottom">
                  {career.sectionOjmTitle}
                </TitleWithLine>
                <div
                  className="section-matters__text"
                  dangerouslySetInnerHTML={{ __html: career.sectionOjmText }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                className="img-fluid section-matters__img"
                src={career.sectionOjmImage?.sourceUrl}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-perks">
        <div className="container">
          <h2 className="section-perks__header">{career.sectionWapgyTitle}</h2>
          <div className="row row-custom">
            {career.sectionWapgyTiles.map((item, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <IconTile
                    icon={video_icons[index]}
                    header={item.sectionWapgyTileTitle}
                    desc={item.sectionWapgyTileText}
                    footer={item.sectionWapgyTileFooter}
                  ></IconTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="section-offers">
        <div className="container">
          <h3 className="section-offers__header">{career.sectionWaclfTitle}</h3>
          <div className="row justify-content-center">
            {career.sectionWaclfOffers.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                  <CareerTile
                    link="#"
                    image={item.sectionWaclfOfferImage?.sourceUrl}
                    header={item.sectionWaclfOfferTitle}
                    desc={item.sectionWaclfOfferLoc}
                  />
                </div>
              );
            })}
          </div>
          <a href="#apply" className="btn">
            Apply
          </a>
        </div>
      </section>
      <span id="apply" className="anchor anchor--apply"></span>
      <NewContactForm />
    </Layout>
  );
};

export default Career;
